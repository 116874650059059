<template>
    <div class="container-fluid">
        <Head></Head>
        <div class="col-lg-12" style="margin-top: -223px;width: 100%">
            <div class="mask"></div>
            <div class="back">
                <div class="back_world">
                    <h1 class="profession01">Our certification</h1>
                    <p class="profession01">Our IT Asset Disposition efficiencies and work ethics have won recognition
                        and qualified for
                        awide range of credentials by employing safe, responsible technology for asset destruction,
                        reuse, and recycling.</p>
                </div>
            </div>

        </div>
        <div class="col-lg-12" style="overflow: hidden;height: 672px;margin-top: 75px">
            <div class="container_cont">
                <div class="container_cont_img">
                    <img alt=""
                         src="../../../assets/image/profession/02.webp" >
                </div>
                <div class="container_cont_world">
                    <p class="profession02">R2</p>
                    <p class="profession02">Global environmental, worker health and safety standard</p>
                </div>
            </div>

            <div class="container_cont">
                <div class="container_cont_img">
                    <img alt=""
                         src="../../../assets/image/profession/03.webp">
                </div>
                <div class="container_cont_world">
                    <p class="profession02">ISO9001</p>
                    <p class="profession02">Internationally recognized Quality Management System</p>
                </div>
            </div>

            <div class="container_cont">
                <div class="container_cont_img">
                    <img alt=""
                         src="../../../assets/image/profession/04.webp">
                </div>
                <div class="container_cont_world">
                    <p class="profession02">ISO14001</p>
                    <p class="profession02">Environmental Management Standard</p>
                </div>
            </div>

            <div class="container_cont">
                <div class="container_cont_img">
                    <img alt=""
                         src="../../../assets/image/profession/05.webp">
                </div>
                <div class="container_cont_world">
                    <p class="profession02">OHSAS18001</p>
                    <p class="profession02">International Occupational Health and Safety Management Standard</p>
                </div>
            </div>

        </div>
        <Bottom style="margin-left: -35px;margin-top: -130px"></Bottom>
    </div>
</template>

<script>
    import {Head, Bottom} from '../commponents'

    export default {
        components: {Bottom, Head},
        name: "Profession",
         metaInfo: {
        meta: [{                 
            name: '暹诺回收官网,手机回收,笔记本回收,数码产品回收,企业回收',
            content: '上海暹诺环保科技有限公司 (简称: 暹诺环保©)是中国领先的环保解决方案提供商，为国内500多家商业和工业企业客户提供回收服务。我们投资开发电子垃圾解决方案，用以适应不断变化的世界。目前该领域不仅包括处理和回收，还包括确保敏感数据的安全的方案，以帮助我们的客户实现零废物的绿色目标。作为中国领先的电子废弃物管理服务供应商，我们的服务宗旨是在最大限度降低影响的同时，又能最大限度地提高资源价值，为所有利益相关者促进经济和环境的可持续发展。'
        }]
        },
        created() {
            $(function () {
                $('.profession01').animate({
                    opacity: '1',
                    top: '125px'
                }, 1000)


                $(window).scroll(function () {
                    var s = $(window).scrollTop();
                    console.log(s)
                    if (s > 350) {
                        $('.profession02').animate({
                            opacity: '1',
                            top: '-15px'
                        }, 1000)
                    }

                });


            })
        }
    }

    $(function () {
        $('.profession01').animate({
            opacity: '1',
            top: '125px'
        }, 1000)


        $(window).scroll(function () {
            var s = $(window).scrollTop();
            console.log(s)
            if (s > 350) {
                $('.profession02').animate({
                    opacity: '1',
                    top: '-15px'
                }, 1000)
            }

        });


    })
</script>

<style scoped>
    .profession01 {
        position: relative;
        opacity: 0;
    }

    .profession02 {
        position: relative;
        opacity: 0;
    }

    .col-lg-12 .mask {
        position: absolute;
        height: 680px;
        background-color: black;
        opacity: 0.2;
        z-index: 1;
    }

    .back {
        height: 680px;
        background: url("../../../assets/image/profession/01.webp") no-repeat;
        background-size: 100% 100%;
        width: 110%;
        margin-left: -30px;
    }

    .back .back_world {
        height: 550px;
        width: 850px;
        position: relative;
        left: 50%;
        top: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        z-index: 9;
    }

    .back_world h1 {
        position: relative;
        top: 150px;
        color: white;
        font-weight: 800;
        font-size: 45px;
    }

    .back_world p {
        position: relative;
        top: 160px;
        color: white;
        font-weight: 800;
        font-size: 25px;
    }

    .col-lg-12 .container_cont {
        height: 672px;
        width: 24%;
        float: left;
        margin: 1px;
        overflow: hidden;

    }

    .container_cont .container_cont_img {
        height: 220px;
        width: 220px;
        position: relative;
        left: 50%;
        top: 20%;
        transform: translate(-50%, -50%);
    }

    .container_cont .container_cont_img img {
        height: 220px;
        width: 220px;
    }

    .container_cont .container_cont_world {
        width: 220px;
        text-align: center;
        position: relative;
        left: 50%;
        top: 20%;

        transform: translate(-50%, -50%);
    }

    .container_cont_world p {
        font-weight: 800;
        color: black;
    }
</style>